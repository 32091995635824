<template>
	<v-layout column fill-height>
		<v-flex text-xs-right shrink>
			<w-btn v-if="!userRoles.isGuest" icon="add" @click="openCreationForm()">{{ $t('customer.bank.actions.add') }}</w-btn>
		</v-flex>
		<w-dialog v-model="dialog" max-width="500px" :title="$t('customer.bank.actions.add')">
			<SimpleBankForm ref="form" v-model="newAccount" :is-valid.sync="isCreationValid" />
			<template v-slot:actions>
				<v-layout justify-end row>
					<w-btn-save :disabled="!isCreationValid" :loading="isCreationLoading" flat @click="createBankAccount" />
				</v-layout>
			</template>
		</w-dialog>
		<v-flex scroll-y>
			<v-expansion-panel v-model="panel">
				<BankPanel
					v-for="(account, index) in bankAccounts"
					:key="account.id"
					:open="panel == index"
					:readonly="userRoles.isGuest"
					:value="account"
					@deleted="deleteBankAccount"
					@updated="updateBankAccount"
				/>
			</v-expansion-panel>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerDetailsBank',
	components: {
		BankPanel: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Bank/BankPanel')
		}),
		SimpleBankForm: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Bank/SimpleBankForm')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			bankAccounts: [],
			dialog: false,
			isCreationLoading: false,
			isCreationValid: false,
			newAccount: {},
			panel: null
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			userRoles: state => state.company.userRoles
		})
	},
	watch: {
		'company.id': {
			handler: function (vendorId) {
				if (vendorId) {
					this.panel = null
					this.loadBankAccounts()
				}
			}
		}
	},
	mounted: function () {
		this.loadBankAccounts()
	},
	methods: {
		createBankAccount: function () {
			this.isCreationLoading = true

			const accountingFirmId = this.accountingFirm.id
			this.service
				.addBankAccount(accountingFirmId, this.company.id, this.newAccount)
				.then(bankAccount => {
					this.bankAccounts.push(bankAccount)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('customer.bank.bank_added'))
					this.dialog = false
				})
				.finally(() => {
					this.isCreationLoading = false
				})
		},
		deleteBankAccount: function (bankAccount) {
			const index = this.bankAccounts.findIndex(({ id }) => id == bankAccount.id)
			if (index >= 0) {
				this.bankAccounts.splice(index, 1)
			}
			this.panel = null
		},
		loadBankAccounts: function () {
			this.bankAccounts.splice(0, this.bankAccounts.length)
			const accountingFirmId = this.accountingFirm.id

			this.service.getBankAccounts(accountingFirmId, this.company.id).then(bankAccounts => {
				bankAccounts.forEach(bankAccount => {
					this.bankAccounts.push(bankAccount)
				})
			})
		},
		openCreationForm: function () {
			this.newAccount = {}
			this.$refs.form.reset()
			this.dialog = true
		},
		updateBankAccount: function (bankAccount) {
			const index = this.bankAccounts.findIndex(({ id }) => id == bankAccount.id)
			if (index >= 0) {
				Object.assign(this.bankAccounts[index], bankAccount)
			}
		}
	}
}
</script>
